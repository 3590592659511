// This file contains details about the meter properties that this project will have. It was
// originally created to fix a circular dependency that existed between
// site-consts -> logger -> site-consts by removing all imports from site-consts.
import appLogger from './logger';

const mlogger = appLogger.getLogger('site-consts');
// mlogger.logLevel = mlogger.loggerLevels.debug;

// const API_BASE = 'https://vinton-meter-swap.carlsapp.com/api';
// const STORAGE_KEY = 'MeterData';
// const WINDOW_TITLE = 'Vinton Meter Swap';
// const APP_VERSION = '2.8';
const ROUTE_KEY = 'Route Name';
const NUM_DETAILS_SHOW = 3;

const PROP_TYPES = {
    BOOL: Boolean,
    STRING: String,
    NUMBER: Number,
    SELECT: 'Select',
    SELECT_MULTI: 'Select Multiple',
    SELECT_EDITABLE: 'Select Editable',
    BARCODE: 'Barcode',
    TEXTAREA: 'Text Area',
    DATE: 'Date',
    ISO_TIMESTAMP: 'ISO Timestamp',
    INSTALL_TYPE: 'Install Type',
    TIMER_TEXT: 'Timer Text',
    CREW_WITH_HOURS: 'Crew With Hours',
};

// const DEFAULT_IMPORT_FILE_FORMAT = 'Fixed Width';
const FEATURE_TOGGLES = {
    GPS_EDITABLE: false,
};

// The meter props is a list of all the properties we have for the meters
//   key - The meter property name that contains the value for this property meter[prop.key]
//   type - The type of the prop. Possible values: Boolean, String, Number
//   TODO Complete this list
const METER_PROPS = [
    { key: 'Route Sequence' },
    { key: 'Customer Address', align: 'left' },
    { key: 'Outgoing Meter Interface Unit #' },
    { key: 'Outgoing Meter #' },
    { key: 'Outgoing Meter Multiplier', type: PROP_TYPES.NUMBER },
    { key: 'Previous Reading', type: PROP_TYPES.NUMBER },
    { key: 'Account #' },
    { key: 'Rate' },
    { key: 'Customer Name' },
    { key: 'Customer Phone #' },
    { key: 'Street Number' },
    { key: 'Street Name' },
    // Old
    // { key: 'Latitude' },
    // { key: 'Longitude' },
    {
        key: 'Crew Members',
        modifiable: true,
        type: PROP_TYPES.CREW_WITH_HOURS,
        rememberLast: true,
        crew: [
            'Greg Austin', 'Sherman Austin', 'CJ Boothe', 'Taylor Campbell', 'Brian Cook',
            'Devin Fowler', 'Hunter McCoy', 'Casey Perkins',
        ],
        onSave: function onCrewMembersSave(meter, lastUsedSettings) {
            const flogger = mlogger.getLogger('crew-members-onsave');
            flogger.logLevel = flogger.loggerLevels.debugall;
            // We only want output from this function when we're debugging issues, so we'll
            // log at a debug or debugall level in this function.
            flogger.debug(`Saving crew members (${JSON.stringify(meter['Crew Members'])}) for our default value`);
            if (!Array.isArray(meter['Crew Members'])) {
                lastUsedSettings.lastCrewSelected = undefined;
                return;
            }
            const lastCrew = [];
            for (let i = 0; i < meter['Crew Members'].length; i++) {
                flogger.debugall(`Massaging crew member ${meter['Crew Members'][i]}`);
                if (Array.isArray(meter['Crew Members'][i])) {
                    // If our crew member is an array, its probably an array of [Name, Hours] and
                    // we only want the name.
                    flogger.debugall('Crew member is an array');
                    lastCrew.push(meter['Crew Members'][i][0]);
                } else if (meter['Crew Members'][i].Name) {
                    // If our crew member is an object with a Name and Hours, grab just the name
                    // and set the hours to 0 for our default value
                    flogger.debugall('Crew member is an object with a Name prop');
                    lastCrew.push({
                        Name: meter['Crew Members'][i].Name,
                        Hours: 0,
                    });
                } else {
                    flogger.debugall('Crew member should be just strings');
                    lastCrew.push(meter['Crew Members'][i]);
                }
            }
            flogger.debug(`Value used for default value: ${JSON.stringify(lastCrew)}`);
            lastUsedSettings.lastCrewSelected = lastCrew;
        },
        defaultValue: function crewMemberDefault(lastUsedSettings) {
            if (lastUsedSettings && lastUsedSettings.lastCrewSelected) {
                return lastUsedSettings.lastCrewSelected;
            }
            return [];
        },
    },
    {
        key: 'Outgoing Meter Reading',
        modifiable: true,
        type: PROP_TYPES.NUMBER,
        htmlType: 'number',
        validationFunc: function outgoingMeterReadingValid(value, meter) {
            if (!value) {
                return 'A value must be entered.';
            }
            if (value < meter['Previous Reading']) {
                return 'The meter reading is less than the previous reading.';
            }
            if (value > meter['Previous Reading'] + 1000) {
                return 'The meter reading shows a consumption of more than 1000 above the previous reading. Is it correct?';
            }
            // The current import file doesn't have a high consumption value, so we don't have a
            // way to check if the value is too high.
            // if (value > this.meter['Last Meter Reading'] + this.meter['High Consumption']) {
            //     return 'This meter consumed more than it has ever previously consumed. Are you
            // sure you read it correctly?';
            // }
            return true;
        },
    }, {
        key: 'Meter Size',
        modifiable: true,
        type: PROP_TYPES.SELECT,
        defaultValue: '5/8"',
        choices: ['5/8"', '3/4"', '1"', '1.5"', '2"', '3"'],
    }, {
        key: 'New Meter Serial',
        modifiable: true,
        type: PROP_TYPES.BARCODE,
    }, {
        key: 'Transmitter ID',
        modifiable: true,
        type: PROP_TYPES.BARCODE,
    }, {
        key: 'Install Date',
        modifiable: true,
        type: PROP_TYPES.DATE,
        defaultValue: function dateDefault() {
            // The date component presents a default value. But, we need this function so the
            // save function can grab a default value for the save.
            const now = new Date();
            return `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`;
        },
    }, {
        key: 'Uninstallable',
        type: PROP_TYPES.BOOL,
        defaultValue: false,
        modifiable: true,
    }, {
        key: 'Town Attention Needed',
        type: PROP_TYPES.BOOL,
        defaultValue: false,
        modifiable: true,
    }, {
        key: 'Notes',
        modifiable: true,
        type: PROP_TYPES.TEXTAREA,
        optional: true,
    },
    {
        name: 'Last Updated',
        key: 'Version',
        type: PROP_TYPES.ISO_TIMESTAMP,
    },
];

const PHOTOS = [
    {
        name: 'Final Reading',
        shortName: 'final-read',
        key: 'Photo URL Final Read',
    }, {
        name: 'Area After',
        shortName: 'post-exchange',
        key: 'Photo URL Post Exchange',
    },
];

// Everything else is pre-computed arrays that can be overriden with custom arrays if needed

const METER_PROPS_BY_KEY = {};
const METER_PROPS_BY_NAME = {};
for (let i = 0; i < METER_PROPS.length; i++) {
    // Apply our default values
    if (METER_PROPS[i].name === undefined) {
        // If we didn't specify a name, the name is the same as the key
        METER_PROPS[i].name = METER_PROPS[i].key;
    }
    if (METER_PROPS[i].type === undefined) {
        // If we didn't specify a type, set as string. This is needed for sorting.
        METER_PROPS[i].type = PROP_TYPES.STRING;
    }
    // Text and value are used by the Vuetify data table header objects
    METER_PROPS[i].text = METER_PROPS[i].name;
    METER_PROPS[i].value = METER_PROPS[i].key;
    if (METER_PROPS[i].modifiable === undefined) {
        METER_PROPS[i].modifiable = false;
    }
    METER_PROPS_BY_KEY[METER_PROPS[i].key] = METER_PROPS[i];
    METER_PROPS_BY_NAME[METER_PROPS[i].name] = METER_PROPS[i];
}

const METER_DETAILS_PROPS = METER_PROPS.filter((prop) => !prop.modifiable);

const METER_EDIT_PROPS = METER_PROPS.filter((prop) => prop.modifiable);

const INITIAL_SORT_PROPS = [
    METER_PROPS_BY_KEY['Route Sequence'],
    METER_PROPS_BY_KEY['Account #'],
];

const METER_LIST_COLS = [
    // METER_PROPS_BY_KEY['Route Name'],
    METER_PROPS_BY_KEY['Route Sequence'],
    METER_PROPS_BY_KEY['Account #'],
    METER_PROPS_BY_KEY['Customer Address'],
];

const PHOTOS_BY_TYPE = {};
for (let i = 0; i < PHOTOS.length; i++) {
    PHOTOS_BY_TYPE[PHOTOS[i].shortName] = PHOTOS[i];
}

// To use this file in a CommonJS environment replace export with module.exports =.
export {
    ROUTE_KEY, PROP_TYPES,
    FEATURE_TOGGLES, METER_PROPS, METER_PROPS_BY_KEY,
    METER_PROPS_BY_NAME, METER_DETAILS_PROPS, NUM_DETAILS_SHOW, METER_EDIT_PROPS,
    INITIAL_SORT_PROPS, METER_LIST_COLS, PHOTOS, PHOTOS_BY_TYPE,
};
